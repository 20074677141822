import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function login(data) {
  return request({
    url: baseURL + '/login',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data
  })
}

export function fetchUserData() {
  return request({
    url: baseURL + '/account',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function userLogin(data) {
  return request({
    url: baseURL + '/user/login',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data
  })
}
