//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchUserData, login} from '@/api/login'
import jwt from "jsonwebtoken";

export default {
  name: 'Login',
	data () {
		return {
			loginForm: {
				username: '',
				password: ''
			},
			rules: {
				username: [
					{ required: true, message: 'Please input username', trigger: 'blur' },
					{ min: 3, message: 'Length should be more than 3 letters', trigger: 'blur' }
				],
				password: [
					{ required: true, message: 'Please input password', trigger: 'change' }
				]
			},
			passwordType: 'password',
			errorMsg: ''
		}
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
	},
	mounted() {
		this.$store.state.loading = false
	},
	methods: {
		showPwd() {
			if (this.passwordType === 'password') {
				this.passwordType = ''
			} else {
				this.passwordType = 'password'
			}
			this.$nextTick(() => {
				this.$refs.password.focus()
			})
		},
		login() {
			this.$refs.loginForm.validate(async (valid) => {
				let username = this.loginForm.username
				let password = this.loginForm.password
				if (valid) {
					this.$session.start()
					const params = new URLSearchParams()
					params.append('username', username.trim())
					params.append('password', password)
					await new Promise((resolve, reject) => {
						login(params).then(response => {
							const check = response.header
							const data = response.body
							if (check.error === 'N') {
								this.$session.set("token", data.token)
								this.$session.set("username", data.userinfo.username)
								this.$session.set("fullname", data.userinfo.fullname)
								this.$session.set("role", data.userinfo.role_id)
								resolve()
							}
						}).catch(error => {
							this.errorMsg = error
							reject(error)
						})
					})

					if (this.$session.get('token')) {
						const privateKey = process.env.VUE_APP_JWT_KEY
						await new Promise((resolve, reject) => {
							fetchUserData().then(response => {
								const check = response.header
								const data = response.body
								if (check.error === 'N') {
									if (data.privileges) {
										const payload = {
											privileges: data.privileges
										}
										const privileges = jwt.sign(payload, privateKey)
										this.$session.set("privileges", privileges)
										resolve()
									}
								}
							}).catch(error => {
								this.errorMsg = error
								reject(error)
							})
						})

						const role = this.$session.get('role') ? this.$session.get('role') : 0
						if (role === 1)
							this.$router.replace('/admin/dashboard')
						else
							this.$router.push('/dashboard')
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		goBack() {
			this.$router.push({path: '/'})
		}
	}
}
